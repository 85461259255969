#portfolio {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 120px;

  @media (min-width: 768px) {
    padding-top: 150px;
  }

  h2 {
    margin-bottom: 70px;
  }

  .lead {
    font-weight: 100;
  }

  .page-header {
    h2 {
      font-weight: 700;
    }
  }

  .portfolio {
    &-tiles {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .portfolio-item {
        position: relative;
        @media (min-width: 768px) {
          width: calc(48% - 0.8em);
        }
        width: 90%;
        border: 1px solid black;
        text-align: left;
        max-height: 280px;
        margin-bottom: 60px;
        color: white;
        -webkit-animation: shadow-expand-br-big 0.7s
          cubic-bezier(0.215, 0.61, 0.355, 1) both;
        animation: shadow-expand-br-big 0.7s cubic-bezier(0.215, 0.61, 0.355, 1)
          both;
        transition: 0.25s ease-in-out;

        .image {
          height: 100%;
          overflow: hidden;
          position: relative;
          transition: 0.25s ease-in-out;

          &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
          }

          img {
            width: 100%;
            transition: 0.25s ease-in-out;
          }
        }

        .content {
          position: absolute;
          top: 0;
          z-index: 100;
          padding: 35px;
          display: flex;
          flex-direction: column;
          height: 100%;
          transition: 0.25s ease-in-out;

          .title {
            margin-bottom: 20px;
            font-weight: 900;
          }

          .description {
            font-size: 14px;
            font-weight: 100;
            letter-spacing: 0.5px;
          }

          .view-more {
            text-align: right;
            margin-top: auto;
            position: relative;
            right: 0;
            transition: 0.25s ease-in-out;
          }
        }

        &:hover {
          -webkit-animation: shadow-contract-big 0.7s
            cubic-bezier(0.215, 0.61, 0.355, 1) both;
          animation: shadow-contract-br-big 0.7s
            cubic-bezier(0.215, 0.61, 0.355, 1) both;

          .content {
            padding-top: 40px;
          }

          .image {
            img {
              transform: scale(1.1);
              transition: 0.2s ease-in;
            }
          }

          .view-more {
            right: -8px;
          }
        }
      }
    }
  }
}

$box-shadow: #dddddd;

@-webkit-keyframes shadow-expand-br-big {
  0% {
    box-shadow: 0px 0px $box-shadow;
  }
  100% {
    box-shadow: 25px 25px $box-shadow;
  }
}

@keyframes shadow-expand-br-big {
  0% {
    box-shadow: 0px 0px;
  }
  100% {
    box-shadow: 25px 25px $box-shadow;
  }
}

@-webkit-keyframes shadow-contract-br-big {
  0% {
    box-shadow: 25px 25px $box-shadow;
  }
  100% {
    box-shadow: 0px 0px $box-shadow;
  }
}

@keyframes shadow-contract-br-big {
  0% {
    box-shadow: 25px 25px $box-shadow;
  }
  100% {
    box-shadow: 0px 0px $box-shadow;
  }
}
