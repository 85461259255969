.pagination {
  justify-content: center;

  .page-item {
    cursor: pointer;
    &:first-child,
    &:last-child {
      .page-link {
        border-radius: 0;
      }
    }
    .page-link {
      background: transparent;
      color: black;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
      }
    }

    &.active {
      .page-link {
        background: #dddddd;
        border-color: transparent;
      }
    }
  }
}
