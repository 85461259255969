.navbar {
  padding: 20px 30px;
  margin-bottom: 60px;
  font-weight: 300;
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  z-index: 300;
  transition: 0.25s ease-in-out;

  .brand {
    transition: 0.2s ease-in-out;
    outline: 0;
    border: 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 16px;

    img {
      width: 25px;
    }
  }

  .navbar-nav {
    .nav-item {
      margin-right: 20px;

      .nav-link {
        outline: 0;
        border: 0;
      }

      .theme-toggle {
        width: 30px;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        background: transparent;
        border: 0;
        outline: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
