@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import '~bootstrap/scss/bootstrap.scss';

@import './components/Contact/style.scss';
@import './components/Footer/style.scss';
@import './components/Navbar/style.scss';
@import './components/Portfolio/style.scss';
@import './components/Snackbar/style.scss';
@import './components/Welcome/style.scss';
@import './components/Pagination/style.scss';

@import './darkmode.scss';

::selection {
  background-color: black;
  color: white;
}

::-moz-selection {
  background-color: black;
  color: white;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  a {
    &:hover {
      color: #7a7a7a;
      text-decoration: none;
    }
  }

  &.is-touch {
    .portfolio-tiles {
      .portfolio-item:hover > span.image {
        transform: scale(0);

        &::before {
          background-color: transparent;
        }
      }
    }
  }
}

.App {
  transition: 0.25s ease-in-out;
  min-height: 100vh;

  @media screen and (max-width: 480px) {
    padding: 3em 0 3em 0;
  }
}

h1 {
  font-weight: 700;
  text-transform: capitalize;
}

.form-control {
  border: 1px solid #ced4da;
  padding: 30px 20px;
  border-radius: 0;
  outline: 0;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
    border-color: #585858;
  }
}

.content-container {
  margin: 0 auto;
  max-width: 960px;
  @media only screen and (min-width: 1900px) {
    max-width: 1180px;
  }
}

.lead {
  line-height: 32px;
  word-spacing: 5px;
  letter-spacing: 0.5px;
}

a {
  color: #333;
  text-decoration: none;
}

.anchor-fancy {
  position: relative;

  a {
    z-index: 200;
    border-bottom: 0;

    &::after {
      content: '';
      background: #e9e9e9;
      position: absolute;
      left: 12px;
      bottom: -4px;
      width: calc(100% - 8px);
      height: calc(100% - 12px);
      z-index: -1;
      transition: 0.35s cubic-bezier(0.25, 0.1, 0, 1.05);
    }

    &:hover {
      color: white;

      &:after {
        background: black;
        left: -5px;
        bottom: -2px;
        width: 105%;
        height: 105%;
      }
    }
  }
}

.button-shadow {
  padding: 15px 25px;
  color: black;
  border-color: black;
  border-radius: 0;
  -webkit-animation: shadow-expand-br 0.7s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: shadow-expand-br 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  transition: 0.25s ease-in-out;

  &:hover {
    background-color: black;
    color: white;
    -webkit-animation: shadow-contract-br 0.7s
      cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: shadow-contract-br 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  }
}

.page-header {
  display: block;

  .heading {
    position: relative;
    margin-bottom: 40px;

    &::before {
      content: '';
      width: 450px;
      border-bottom: 1px solid #e8e8e8;
      position: absolute;
      left: 0;
      top: 13px;

      @media screen and (max-width: 480px) {
        width: 10px;
        border-color: red;
      }
    }

    &::after {
      content: '';
      width: 450px;
      border-bottom: 1px solid #e8e8e8;
      position: absolute;
      right: 0;
      top: 13px;

      @media screen and (max-width: 480px) {
        width: 10px;
        border-color: red;
      }
    }
  }
}

.font-weight-extra-bold {
  font-weight: 900 !important;
}

.sm-list {
  @media screen and (max-width: 1280px) {
    display: none;
  }
}

@-webkit-keyframes shadow-expand-br {
  0% {
    box-shadow: 0px 0px;
  }
  100% {
    box-shadow: 8px 8px;
  }
}

@keyframes shadow-expand-br {
  0% {
    box-shadow: 0px 0px;
  }
  100% {
    box-shadow: 8px 8px;
  }
}

@-webkit-keyframes shadow-contract-br {
  0% {
    box-shadow: 8px 8px;
  }
  100% {
    box-shadow: 0px 0px;
  }
}

@keyframes shadow-contract-br {
  0% {
    box-shadow: 8px 8px;
  }
  100% {
    box-shadow: 0px 0px;
  }
}
