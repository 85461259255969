.snackbar {
  padding-left: 0;
  position: fixed;
  list-style: none;
  top: 40%;
  left: 0;
  text-align: center;
  -webkit-animation: slide-in-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  li {
    margin-bottom: 0.75em;

    a {
      opacity: 0.5;
      transition: opacity 0.4s ease;
      color: #616161;

      &:hover {
        opacity: 1;
        color: black;
      }
    }

    &.snackbar-text {
      transform: rotate(270deg);
      margin-top: 55px;
      margin-bottom: 0px;
      font-weight: 100;
      letter-spacing: 3px;
      text-transform: uppercase;
      font-size: 12px;
      padding-bottom: 3px;
    }
  }

  @media screen and (max-width: 1280px) {
    display: none;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
